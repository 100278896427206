.titleHeader {
    display: flex;
    align-content: center;
    justify-content: center;
}

.text {
    display: block;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 0;
    padding-right: 6%;
}

.title {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
}

.side {
    display: flex;
    justify-content: center;
}

.side img {
    width:80%;
    height: 80%
}

@media all and (min-width: 800px) {
    .titleHeader {
        flex-direction: row;
        justify-content: center;
        margin-top: 10px;
        margin-bottom: 20px;
    }
    .text {
        width: 55%;
    }
    .title {
        text-align: left;
        max-width: 60%;
    }
    .side {
        width: 50%;
        align-self: center;
    }
}

@media all and (max-width: 800px) {
    .titleHeader {
        flex-direction: column;
        justify-content: center;
        margin: auto;
    }
    .text {
        width: 95%;
        margin: auto;
        padding: 0 2%;
    }
    .title {
        text-align: center;
        max-width: 100%
    }
    .side {
        width: 100%;
        align-self: center;
    }
}

