.App {
  text-align: center;
  background-color: #f4f5f8;
  height: 100%;
}

.pageContainer {
  background-color: #f4f5f8;
  display: flex;
  justify-content: center;
  align-content: center;
}

.page {
  /*background-color: #fbfcfd;*/
  background-color: #fafafa;
  width: 100%;
  max-width: 1600px;
}


