
.selected {
    color: #3ca8c6 !important;
}

.itemSelector:hover:not(.active) {
    color: #3ca8c6;
}

.itemSelector {
    composes: pd16 tac from '../../index.css';
    text-decoration: none;
    display: block;
    color: black;
}

.navItem {
    composes: fltr from '../../index.css';
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 16px;
    vertical-align: center;
    margin: 0;
}


/*For the newsletter modal so it fits nicely in the header*/
.imgContainer{
    margin: auto;
    height: 100%;
    padding: 8px;
}
.imgContainer img {
    height: 50%;
    width: auto;
    margin: auto;
    margin-top: 8px;
    cursor: pointer;
}


.navList {
    composes: m0 fltr from '../../index.css';
    display: flex;
    list-style-type: none;
    align-items: center;
    padding: 0 5%;
    height: 100%;

}

.navLogo {

    composes: fltl pds14 tal from '../../index.css';
    position: absolute;
    top: 30%;
    white-space: nowrap;
    height: 100%;
}

.logoSelector {
    compose: pd0 m0 tal form '../../index.css';
    text-decoration: none;
    display: block;
    color: black;
}

.logoImg{
    width: 30px;
    height: auto;
    resizeMode: 'contain';
    float: left;

}

.logoHeader {
    composes: bolder from '../../index.css';
    font-size: 16px;
}

.logoSlogan {
    font-size: calc(6px + 1vmin);
}


.navBar{
    composes: m0 from '../../index.css';
    top: 0;
    width: 100%;
    height: 70px;
    overflow: hidden;
    position: sticky;
    position: -webkit-sticky;
    background-color: white;
    max-width: 1600px;
    margin: auto;
}

.linkedinLogo {
    margin: auto;
    padding-left: 12px;
}

.linkedinLogo a img {
    width: 30px;
    height: 30px;
    display: block;
    margin: 0 auto;
}

.navbarLG {

}

.navBarSM {

}

.navItemSM {
    display: flex;
    composes: tac from '../../index.css';
    float: none;
    margin: auto 10px;
    align-content: center;
    justify-content: center;
}

.navItemSM a {
    width: 100%;
}


.navListSM {
    composes: m0 from '../../index.css';
    list-style-type: none;
    overflow: hidden;
    padding: 0;
    height: 100%;
    background-color: white;
}