html {
    height: 100%;
}

body {
    height: 100%;
    background-color: #f1f2f4;
    margin: 0;
  padding: 0;
    font-family: "Heebo", sans-serif;
    font-size: 1.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    -webkit-transform: scale(1);
            transform: scale(1);

}


a {
    text-decoration: none;
    color: #3ca8c6;
}

h1 {
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 600;
    font-size: calc(2rem + (18 * (100vw - 400px)/ 624));
    margin-bottom: 0;
}

h2 {
    font-family: 'IBM Plex Sans, Arimo', sans-serif;
    font-weight: 300;
    font-size: calc(10px + 2vw);
}

h3 {
    display: flex;
    margin: 0;
}


p {
    font-family: "Heebo", sans-serif;
    font-size: 1em;
    color: #404040;
}


.fltr {
  float: right;
}

.fltl {
  float:left;
}

.hightlight-txt {
  color: #0074D9;

}

.m0 {
  margin: 0;
}

.pd0 {
  padding: 0;
}

.pd16 {
  padding: 16px;
}

.ovh {
  overflow: hidden;
}

.tac {
  text-align: center;
}

.tal {
  text-align: left;
}

.pds14 {
  padding: 0px 14px;
}

.bolder {
  font-weight: bolder;
}

.fs14 {
  font-size: 14px
}


.button_btn__15kTT {
    border-radius: 0;
    padding: 6px;
    display: flex;
    align-items: center;
    align-self: center;
    font-size: 2.2vmax;
    background-color: transparent;
    border: 2px solid lightgrey;
    max-width: 250px;
}

.button_btn__15kTT:hover, .button_imgOnly__19OaD:hover {
    cursor: pointer;
    color: #3ca8c6;
}

.button_btn__15kTT p {
    padding: 0 12px;
    margin: auto;
}


.button_imgOnly__19OaD {
    height: 100%;
}

.button_buttonContainer__ib4vN {
    display: flex;
}

@media all and (min-width: 800px) {
    .button_buttonContainer__ib4vN {
        justify-content: flex-start;
        align-items: flex-start;
    }
}

@media all and (max-width: 800px) {
    .button_buttonContainer__ib4vN {
        justify-content: center;
        align-items: center;
    }
}

.button_button__3ZzrB {
    display: flex;
    width: 100%;
    max-width: 300px;
    background-color: #3ca8c6;
    border-radius: 4px;
    overflow: auto;
    margin: 10px 0;
    text-align: center;
}

.button_button__3ZzrB a {
    width: 100%;
    height: 100%;
    text-decoration: none;
    text-transform: uppercase;
}

.button_buttonText__wY9-z {
    display: flex;
    padding: 8px 30px;
    vertical-align: center;
    color: white;
}
html {
    height: 100%;
}

body {
    height: 100%;
    background-color: #f1f2f4;
    margin: 0;
  padding: 0;
    font-family: "Heebo", sans-serif;
    font-size: 1.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    -webkit-transform: scale(1);
            transform: scale(1);

}


a {
    text-decoration: none;
    color: #3ca8c6;
}

h1 {
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 600;
    font-size: calc(2rem + (18 * (100vw - 400px)/ 624));
    margin-bottom: 0;
}

h2 {
    font-family: 'IBM Plex Sans, Arimo', sans-serif;
    font-weight: 300;
    font-size: calc(10px + 2vw);
}

h3 {
    display: flex;
    margin: 0;
}


p {
    font-family: "Heebo", sans-serif;
    font-size: 1em;
    color: #404040;
}


.index_fltr__3AvhL {
  float: right;
}

.index_fltl__21QWx {
  float:left;
}

.index_hightlight-txt__1GyD6 {
  color: #0074D9;

}

.index_m0__KoXe4 {
  margin: 0;
}

.index_pd0__uTOud {
  padding: 0;
}

.index_pd16__217r5 {
  padding: 16px;
}

.index_ovh__9kUNC {
  overflow: hidden;
}

.index_tac__SFlCr {
  text-align: center;
}

.index_tal__2aJ-z {
  text-align: left;
}

.index_pds14__2goXn {
  padding: 0px 14px;
}

.index_bolder__28MCT {
  font-weight: bolder;
}

.index_fs14__2GeJk {
  font-size: 14px
}

.navbar_selected___gBP4 {
    color: #3ca8c6 !important;
}

.navbar_itemSelector__2HAsm:hover:not(.navbar_active__3PFXw) {
    color: #3ca8c6;
}

.navbar_itemSelector__2HAsm {
    text-decoration: none;
    display: block;
    color: black;
}

.navbar_navItem__2Paj0 {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 16px;
    vertical-align: center;
    margin: 0;
}


/*For the newsletter modal so it fits nicely in the header*/
.navbar_imgContainer__rrCtH{
    margin: auto;
    height: 100%;
    padding: 8px;
}
.navbar_imgContainer__rrCtH img {
    height: 50%;
    width: auto;
    margin: auto;
    margin-top: 8px;
    cursor: pointer;
}


.navbar_navList__D60gr {
    display: flex;
    list-style-type: none;
    align-items: center;
    padding: 0 5%;
    height: 100%;

}

.navbar_navLogo__1_ID_ {
    position: absolute;
    top: 30%;
    white-space: nowrap;
    height: 100%;
}

.navbar_logoSelector__2iVD2 {
    compose: pd0 m0 tal form '../../index.css';
    text-decoration: none;
    display: block;
    color: black;
}

.navbar_logoImg__22Hj8{
    width: 30px;
    height: auto;
    resizeMode: 'contain';
    float: left;

}

.navbar_logoHeader__DMOxW {
    font-size: 16px;
}

.navbar_logoSlogan__2Zb2q {
    font-size: calc(6px + 1vmin);
}


.navbar_navBar__3njBF{
    top: 0;
    width: 100%;
    height: 70px;
    overflow: hidden;
    position: sticky;
    position: -webkit-sticky;
    background-color: white;
    max-width: 1600px;
    margin: auto;
}

.navbar_linkedinLogo__T_krW {
    margin: auto;
    padding-left: 12px;
}

.navbar_linkedinLogo__T_krW a img {
    width: 30px;
    height: 30px;
    display: block;
    margin: 0 auto;
}

.navbar_navbarLG__kAmVO {

}

.navbar_navBarSM__3tAbU {

}

.navbar_navItemSM__2Y_ip {
    display: flex;
    float: none;
    margin: auto 10px;
    align-content: center;
    justify-content: center;
}

.navbar_navItemSM__2Y_ip a {
    width: 100%;
}


.navbar_navListSM__3rCgc {
    list-style-type: none;
    overflow: hidden;
    padding: 0;
    height: 100%;
    background-color: white;
}
.layout_row__3onr7 {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    padding: 20px 6%;
    overflow:auto;
}
.layout_textRow__1m0Kb {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    padding: 0;
    overflow:auto;
}

.layout_title__21vg6 {
    font-size: 48px;
    margin: 0;
}
.layout_header__sMC8Z {
    font-size: calc(22px + 1vw);
    margin: 0 0 12px 0;
    font-weight: 100;
}


.layout_text__1rmrg {
    margin: 0;
    font-weight: 400;
    font-size: 20px;
    padding: 0px 0px;
    color: #262626;

}

.layout_quote__2tJsI {
    font-style: italic;
}

.layout_boldText__AHFlR {
    margin: 0;
    color: black;
    font-size: 22px;
    font-weight: 500;
}

.layout_img__n0iXC {
    width: 50%;
}

.layout_imgSrc__gwTQL {
    width: 100%;
    height: auto;
    object-fit: contain;
}

.layout_columnContainer__3UCk8 {
    display: flex;
    justify-content: center;
    align-content: center;
    text-align: left;
    vertical-align: top;
}

.layout_column__hBCo7 {
    width: 100%;
    display: flex;
    margin: 0;
    flex: 1 1;
    flex-direction: column;
    text-align: left;
    padding: 10px 1%;
}

.layout_column__hBCo7 ul {
    margin-top: 0;
}

.layout_column__hBCo7 ul li {
    font-weight: 400;
    font-size: 20px;
    color: #262626;
}

.layout_textColumn__1JzYn {
    display: flex;
    margin: 0;
    flex-direction: column;
    align-content: flex-end;
    justify-content: flex-start;
    text-align: left;
    padding: 0 2%;
}

.layout_textColumnContainer__3jl6r {
    display: flex;
    align-content: flex-start;
    justify-content: flex-start;
}

.layout_icon__2fUNo {
    width: 40px;
    height: auto;
}

.layout_post__2SLm2 {
    display: flex;
    #background-color: white;
    width: 100%;
    border: 1px solid #ededed;
    border-radius: 10px;
    box-shadow: 2px 2px 4px rgba(0,0,0,.2);
    margin: 15px 0;
}

/*
.post:hover {
    margin: 10px 0 20px 0;
    box-shadow: 2px 2px 8px rgba(0,0,0,.2);
}
*/

.layout_imgPost__3l21p {
    display: flex;
    align-self: center;
    width: 10%;
    max-width: 60px;
    margin: 18px 20px;
}

.layout_textPost__326Ar {
    display: flex;
    flex-direction: column;
    width: 75%;
    text-align: left;
    padding: 0 2%0 2%;
    margin: 12px 10px 12px 28px;

}


.layout_card__2Sp7n {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    text-align: center;
    border-radius: 10px;
    box-shadow: 2px 2px 4px rgba(0,0,0,.2);
    margin: 15px 0;
    padding: 10px;
    background-color: white;
}


.layout_card__2Sp7n:hover {
    margin: 10px 0 20px 0;
    box-shadow: 2px 2px 8px rgba(0,0,0,.2);
}

.layout_cardImg__1ncbY{
    width: 50%;
    height: auto;
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: auto;
    margin-right: auto;
}

.layout_cardText__3DaOz {
    margin: 12px 12px;
    padding: 16px 32px;
}

.layout_textAlignLeft__3pAez {
    text-align: left;
}
.title_titleHeader__3KwuX {
    display: flex;
    align-content: center;
    justify-content: center;
}

.title_text__3tVrr {
    display: block;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 0;
    padding-right: 6%;
}

.title_title__3uFug {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
}

.title_side__2-FpH {
    display: flex;
    justify-content: center;
}

.title_side__2-FpH img {
    width:80%;
    height: 80%
}

@media all and (min-width: 800px) {
    .title_titleHeader__3KwuX {
        flex-direction: row;
        justify-content: center;
        margin-top: 10px;
        margin-bottom: 20px;
    }
    .title_text__3tVrr {
        width: 55%;
    }
    .title_title__3uFug {
        text-align: left;
        max-width: 60%;
    }
    .title_side__2-FpH {
        width: 50%;
        align-self: center;
    }
}

@media all and (max-width: 800px) {
    .title_titleHeader__3KwuX {
        flex-direction: column;
        justify-content: center;
        margin: auto;
    }
    .title_text__3tVrr {
        width: 95%;
        margin: auto;
        padding: 0 2%;
    }
    .title_title__3uFug {
        text-align: center;
        max-width: 100%
    }
    .title_side__2-FpH {
        width: 100%;
        align-self: center;
    }
}


.page {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    padding: 20px 6%;
    overflow:auto;
}

.break {
    border-bottom: 5px solid #262626;
    height: 12px;
    width: 100%;
}

.calender_calender__k72R4 {
    margin: 0;
    width: 100%;
    height: 1000px;
    padding: 0;
    background-color: #E9EDF3;
    overflow: visible;
}



@media all and (max-width: 600px) {
    .calender_calender__k72R4 {
        height: 1000px;
        min-width: 425px;
    }
    .calender_calender__k72R4 iframe {
        overflow: hidden;
    }
}

@media all and (min-width: 600px) {
    .calender_calender__k72R4 {
        min-width: 500px;
    }
}


.contact_image__2Tfyx {
    margin: 0;
    border-radius: 50%;
    width: 160px;
    height: 160px;
}

.contact_contactName__2ll4x {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 8px 0;
}



.contact_linkedin__2wUDO {
    margin: 0 12px;
}

.contact_linkedin__2wUDO img {
    width: 20px;
    height: auto;
}

.contact_card__2vSy4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    flex: 1 1;
    width: 85%;
    margin: 2% 2% 2% auto;
    padding: 18px;
    box-shadow: 2px 2px 6px grey;
    background-color: white;

}

.contact_column__1x22Q {
    display: flex;
    flex-direction: column;
}

.contact_calander__afGMY {
    display: flex;
    justify-content: center;
    margin: 0;
}

@media all and (max-width: 560px) {
    .contact_calander__afGMY {
        flex-direction: column-reverse;
    }
    .contact_column__1x22Q {
        flex-direction: column;
    }
    .contact_calanderContact__3GRrf {
        margin: 0 2%;
    }

}

@media all and (min-width: 560px) and (max-width: 892px) {
    .contact_calander__afGMY {
        flex-direction: column-reverse;
    }
    .contact_column__1x22Q {
        flex-direction: row;
    }
    .contact_calanderContact__3GRrf {
        margin: 0 2%;
    }

}

@media all and (min-width: 892px){
    .contact_calander__afGMY {
        flex-direction: row;
    }
    .contact_column__1x22Q {
        flex-direction: column;
    }
    .contact_calanderContact__3GRrf{
        margin: 8% auto 2% 2% ;
    }
}


.contact_calanderContact__3GRrf {
    flex: 1 1;

}

.contact_calanderCalander__2G7Bc {
    margin: 0 2%;
    flex: 2 1;
}
.imgoverlay_imgOverlay__1UxuI {
    width: 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: auto;
    padding: 20;
    align-items: center;
}

.imgoverlay_darken__4fnN6 {
    width: 100%;
    height: 100%;
    display: inline-flex;
}

.imgoverlay_body__ry5hm {
    margin: auto;
}

.imgoverlay_txt__leGun {
    font-size: 2.5vw;

}
.footer_footer__1R8bw {
    text-align: center;
    width: 100%;
    font-size: 12px;
    max-width: 1600px;
    margin: 0 auto;
}
.app_App__2qmNg {
  text-align: center;
  background-color: #f4f5f8;
  height: 100%;
}

.app_pageContainer__34hqH {
  background-color: #f4f5f8;
  display: flex;
  justify-content: center;
  align-content: center;
}

.app_page__2-oMl {
  /*background-color: #fbfcfd;*/
  background-color: #fafafa;
  width: 100%;
  max-width: 1600px;
}



