.calender {
    margin: 0;
    width: 100%;
    height: 1000px;
    padding: 0;
    background-color: #E9EDF3;
    overflow: visible;
}



@media all and (max-width: 600px) {
    .calender {
        height: 1000px;
        min-width: 425px;
    }
    .calender iframe {
        overflow: hidden;
    }
}

@media all and (min-width: 600px) {
    .calender {
        min-width: 500px;
    }
}

