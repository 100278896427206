.page {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    padding: 20px 6%;
    overflow:auto;
}

.break {
    border-bottom: 5px solid #262626;
    height: 12px;
    width: 100%;
}
