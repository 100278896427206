html {
    height: 100%;
}

body {
    height: 100%;
    background-color: #f1f2f4;
    margin: 0;
  padding: 0;
    font-family: "Heebo", sans-serif;
    font-size: 1.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    transform: scale(1);

}


a {
    text-decoration: none;
    color: #3ca8c6;
}

h1 {
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 600;
    font-size: calc(2rem + (18 * (100vw - 400px)/ 624));
    margin-bottom: 0;
}

h2 {
    font-family: 'IBM Plex Sans, Arimo', sans-serif;
    font-weight: 300;
    font-size: calc(10px + 2vw);
}

h3 {
    display: flex;
    margin: 0;
}


p {
    font-family: "Heebo", sans-serif;
    font-size: 1em;
    color: #404040;
}


.fltr {
  float: right;
}

.fltl {
  float:left;
}

.hightlight-txt {
  color: #0074D9;

}

.m0 {
  margin: 0;
}

.pd0 {
  padding: 0;
}

.pd16 {
  padding: 16px;
}

.ovh {
  overflow: hidden;
}

.tac {
  text-align: center;
}

.tal {
  text-align: left;
}

.pds14 {
  padding: 0px 14px;
}

.bolder {
  font-weight: bolder;
}

.fs14 {
  font-size: 14px
}
