.row {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    padding: 20px 6%;
    overflow:auto;
}
.textRow {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    padding: 0;
    overflow:auto;
}

.title {
    font-size: 48px;
    margin: 0;
}
.header {
    font-size: calc(22px + 1vw);
    margin: 0 0 12px 0;
    font-weight: 100;
}


.text {
    composes: pd0 from '../../index.css';
    margin: 0;
    font-weight: 400;
    font-size: 20px;
    padding: 0px 0px;
    color: #262626;

}

.quote {
    font-style: italic;
}

.boldText {
    margin: 0;
    color: black;
    font-size: 22px;
    font-weight: 500;
}

.img {
    width: 50%;
}

.imgSrc {
    width: 100%;
    height: auto;
    object-fit: contain;
}

.columnContainer {
    display: flex;
    justify-content: center;
    align-content: center;
    text-align: left;
    vertical-align: top;
}

.column {
    width: 100%;
    display: flex;
    margin: 0;
    flex: 1;
    flex-direction: column;
    text-align: left;
    padding: 10px 1%;
}

.column ul {
    margin-top: 0;
}

.column ul li {
    font-weight: 400;
    font-size: 20px;
    color: #262626;
}

.textColumn {
    display: flex;
    margin: 0;
    flex-direction: column;
    align-content: flex-end;
    justify-content: flex-start;
    text-align: left;
    padding: 0 2%;
}

.textColumnContainer {
    display: flex;
    align-content: flex-start;
    justify-content: flex-start;
}

.icon {
    width: 40px;
    height: auto;
}

.post {
    display: flex;
    #background-color: white;
    width: 100%;
    border: 1px solid #ededed;
    border-radius: 10px;
    box-shadow: 2px 2px 4px rgba(0,0,0,.2);
    margin: 15px 0;
}

/*
.post:hover {
    margin: 10px 0 20px 0;
    box-shadow: 2px 2px 8px rgba(0,0,0,.2);
}
*/

.imgPost {
    display: flex;
    align-self: center;
    width: 10%;
    max-width: 60px;
    margin: 18px 20px;
}

.textPost {
    display: flex;
    flex-direction: column;
    width: 75%;
    text-align: left;
    padding: 0 2%0 2%;
    margin: 12px 10px 12px 28px;

}


.card {
    display: flex;
    flex: 1;
    flex-direction: column;
    text-align: center;
    border-radius: 10px;
    box-shadow: 2px 2px 4px rgba(0,0,0,.2);
    margin: 15px 0;
    padding: 10px;
    background-color: white;
}


.card:hover {
    margin: 10px 0 20px 0;
    box-shadow: 2px 2px 8px rgba(0,0,0,.2);
}

.cardImg{
    width: 50%;
    height: auto;
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: auto;
    margin-right: auto;
}

.cardText {
    margin: 12px 12px;
    padding: 16px 32px;
}

.textAlignLeft {
    text-align: left;
}