.imgOverlay {
    composes: tac from '../../index.css';
    width: 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: auto;
    padding: 20;
    align-items: center;
}

.darken {
    width: 100%;
    height: 100%;
    display: inline-flex;
}

.body {
    margin: auto;
}

.txt {
    font-size: 2.5vw;

}