.image {
    margin: 0;
    border-radius: 50%;
    width: 160px;
    height: 160px;
}

.contactName {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 8px 0;
}



.linkedin {
    margin: 0 12px;
}

.linkedin img {
    width: 20px;
    height: auto;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    flex: 1;
    width: 85%;
    margin: 2% 2% 2% auto;
    padding: 18px;
    box-shadow: 2px 2px 6px grey;
    background-color: white;

}

.column {
    display: flex;
    flex-direction: column;
}

.calander {
    display: flex;
    justify-content: center;
    margin: 0;
}

@media all and (max-width: 560px) {
    .calander {
        flex-direction: column-reverse;
    }
    .column {
        flex-direction: column;
    }
    .calanderContact {
        margin: 0 2%;
    }

}

@media all and (min-width: 560px) and (max-width: 892px) {
    .calander {
        flex-direction: column-reverse;
    }
    .column {
        flex-direction: row;
    }
    .calanderContact {
        margin: 0 2%;
    }

}

@media all and (min-width: 892px){
    .calander {
        flex-direction: row;
    }
    .column {
        flex-direction: column;
    }
    .calanderContact{
        margin: 8% auto 2% 2% ;
    }
}


.calanderContact {
    flex: 1;

}

.calanderCalander {
    margin: 0 2%;
    flex: 2;
}