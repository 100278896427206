
.btn {
    border-radius: 0;
    padding: 6px;
    display: flex;
    align-items: center;
    align-self: center;
    font-size: 2.2vmax;
    background-color: transparent;
    border: 2px solid lightgrey;
    max-width: 250px;
}

.btn:hover, .imgOnly:hover {
    cursor: pointer;
    color: #3ca8c6;
}

.btn p {
    padding: 0 12px;
    margin: auto;
}


.imgOnly {
    height: 100%;
}

.buttonContainer {
    display: flex;
}

@media all and (min-width: 800px) {
    .buttonContainer {
        justify-content: flex-start;
        align-items: flex-start;
    }
}

@media all and (max-width: 800px) {
    .buttonContainer {
        justify-content: center;
        align-items: center;
    }
}

.button {
    display: flex;
    width: 100%;
    max-width: 300px;
    background-color: #3ca8c6;
    border-radius: 4px;
    overflow: auto;
    margin: 10px 0;
    text-align: center;
}

.button a {
    width: 100%;
    height: 100%;
    text-decoration: none;
    text-transform: uppercase;
}

.buttonText {
    display: flex;
    padding: 8px 30px;
    vertical-align: center;
    color: white;
}